<template>
  <div>
    <div :class="$style.block">
      <div class="row">
        <div class="col-xl-12">
          <div :class="$style.inner">
            <div :class="$style.form">
              <div :class="$style.logoContainer">
                <img v-lazy="$companyLogoPath" alt="">
              </div>
              <a-form class="login-form" :form="form" @submit="handleSubmit">
                <a-form-item
                  :validate-status="error('email') ? 'error' : ''"
                  :help="error('email') || ''"
                  label="E-mail">
                  <a-input
                    placeholder="E-mail"
                    type="email"
                    v-decorator="['email', { rules: [{ required: true, message: 'E-mail musí být vyplněn!' }]}]"
                  >
                    <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25);"/>
                  </a-input>
                </a-form-item>
                <a-form-item
                  :validate-status="error('password') ? 'error' : ''"
                  :help="error('password') || ''"
                  label="Heslo">
                  <a-input
                    placeholder="Heslo"
                    type="password"
                    v-decorator="['password', { rules: [{ min: 6, message: 'Heslo musí mít délku alespoň 6 znaků' }, { required: true, message: 'Heslo musí být vyplněno!' }]}]"
                  >
                    <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25);"/>
                  </a-input>
                </a-form-item>
                <a-form-item class="form-checkbox">
                  <a-checkbox
                    v-decorator="['remember', { valuePropName: 'checked', initialValue: true }]"
                  >
                    Zapamatovat si mě
                  </a-checkbox>
                  <router-link
                    class="text-primary utils__link--blue utils__link--underlined"
                    to="/auth/forgot"
                  >Zapomněli jste heslo?</router-link>
                </a-form-item>
                <a-alert class="mt-3" v-if="errorBag" type="error" :message="errorBag"></a-alert>
                <div class="form-actions">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    class="login-form-button"
                    :block="true"
                    size="large"
                    :disabled="loading || hasErrors(form.getFieldsError())"
                    :loading="loading"
                  >Přihlásit se</a-button>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dealWithSuccess, dealWithErrors } from '../../../store/import/http'
import { hasErrors } from '../../../services/forms'

export default {
  data: function () {
    return {
      loading: false,
      form: this.$form.createForm(this),
      hasErrors,
      errorBag: null,
    }
  },
  methods: {
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.errorBag = null
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$auth.login(values.email, values.password)
            .then((message) => {
              const data = message.data
              const accessToken = data.access_token
              const refreshToken = data.refresh_token
              if (values.remember) {
                localStorage.setItem('access_token', accessToken)
                localStorage.setItem('refresh_token', refreshToken)
              } else {
                sessionStorage.setItem('access_token', accessToken)
              }
              this.$store.commit('UPDATE_ACCESS_TOKEN', { accessToken })
              this.$store.commit('SET_REFRESH_TOKEN', { refreshToken })
              dealWithSuccess({
                message: 'Byl jste přihlášen',
                description: 'Úspěšně jste se přihlásil do administrace!',
              })
            })
            .catch((response) => {
              dealWithErrors(response)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
